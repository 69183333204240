<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Add Voucher
        <div class="card-header-actions">
          <a class="card-header-action" href="voucher" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
            <div class="sk-three-bounce">
                <div class="sk-child sk-bounce1"></div>
                <div class="sk-child sk-bounce2"></div>
                <div class="sk-child sk-bounce3"></div>
            </div>
        </div>
        <b-row>
          <b-col sm="12">
            <!--<h6>Simple Form</h6>-->
            <b-form v-on:submit.prevent="onSubmit" novalidate action="https://vuejs.org/">
              <b-form-group id="rContentInputGroup4"
                            label="Voucher Type"
                            label-for="rContent">
                <b-form-select id="v_type"
                  v-model.lazy.trim="$v.form.v_type.$model"
                  @change="updateMaxAttemp"
                  :state="chkState('v_type')"
                  aria-describedby="typeFeedback"
                  :plain="true"
                  :options="[{value: '', text:'Please select'},
                              {value: 'one_time_use', text: 'Voucher Celengan (Saldo IDR Anorganik)'},
                              {value: 'wallet', text: 'Celengan (Saldo IDR Organik)'},
                              {value: 'gold', text: 'Gold Voucher'},
                              {value: 'serveral_use', text: 'Voucher IDR Multiple Redeem' },
                              {value: 'partnership', text: 'Voucher Partnership (Single dan Multiple Redeem)'}]">
                </b-form-select>
                <b-form-invalid-feedback id="typeFeedback" v-for="(error , index) in errors.form.v_type" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- <b-form-group id="merchant"
                            v-if="form.v_type === 'partnership'"
                            label="Merchant"
                            label-for="merchant">
                <b-form-select id="merchant"
                  v-model="form.merchant"
                  :plain="true"
                  :options="merchantOptions">
                </b-form-select>
              </b-form-group> -->
              <b-form-group id="user_type"
                            v-if="(form.v_type === 'partnership') || (form.v_type === 'serveral_use')"
                            label="User Type"
                            label-for="user_type">
                <b-form-select id="user_type"
                  v-model.lazy.trim="form.user_type"
                  aria-describedby="userTypeFeedback"
                  :plain="true"
                  :options="form.v_type === 'partnership' ? optionsPartnership : optionsMultiple">
                </b-form-select>
                <b-form-invalid-feedback id="userTypeFeedback" v-for="(error , index) in errors.form.behavior" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-row
                v-if="form.v_type === 'partnership' && form.user_type === 'Register At'"
              >
                <b-col sm>
                  <b-form-group
                    label="Register Start :"
                    label-for="daterange"
                  >
                    <b-input-group
                    >
                      <date-picker
                        v-model="register_start"
                        format="YYYY-MM-DD"
                        type="datetime"
                        lang="en"
                        value-type="format"
                        placeholder="YYYY-MM-DD"
                      ></date-picker>
                      <!-- <single-picker id="daterange" :start="startDate" @setDate="getPricing"></single-picker> -->
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm>
                  <b-form-group
                    label="Register End (Optional) :"
                    label-for="daterange"
                  >
                    <b-input-group
                    >
                      <date-picker
                        v-model="register_end"
                        format="YYYY-MM-DD"
                        type="datetime"
                        lang="en"
                        value-type="format"
                        placeholder="YYYY-MM-DD"
                      ></date-picker>
                      <!-- <single-picker id="daterange" :start="startDate" @setDate="getPricing"></single-picker> -->
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group id="days"
                            v-if="form.v_type === 'partnership' && form.user_type === 'Last Time Buy'"
                            label="Days"
                            label-for="day">
                <b-form-input id="day"
                              type="number"
                              v-model.lazy.trim="$v.form.day.$model"
                              :state="chkState('day')"
                              aria-describedby="dayFeedback"
                              placeholder="0"
                              autocomplete='given-name'
                              autofocus />
                <b-form-invalid-feedback id="dayFeedback" >
                  <span v-if="!$v.form.day.required">- Jumlah hari tidak boleh kosong!</span>
                  <span v-if="!$v.form.day.numeric">- Jumlah hari tidak boleh kurang dari 0!</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="behavior"
                            v-if="form.v_type === 'partnership'"
                            label="Redeem Type"
                            label-for="behavior">
                <b-form-select id="behavior"
                  v-model.lazy.trim="form.behavior"
                  aria-describedby="typeFeedback"
                  :plain="true"
                  :options="[{value: 'single', text: 'Single Nominal Redeem'},
                            {value: 'multiple', text: 'Multiple Nominal Redeem'}]">
                </b-form-select>
                <b-form-invalid-feedback id="typeFeedback" v-for="(error , index) in errors.form.behavior" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-row>
                <b-col sm="6" v-if="this.form.v_type !== 'serveral_use'">
                  <b-form-group id="nameInputGroup1"
                                label="Voucher Name"
                                label-for="name">
                    <b-form-input id="name"
                                  type="text"
                                  v-model.lazy.trim="$v.form.name.$model"
                                  :state="chkState('name')"
                                  aria-describedby="nameFeedback"
                                  placeholder="Please Enter Voucher Name"
                                  autocomplete='given-name'
                                  autofocus />
                    <b-form-invalid-feedback id="nameFeedback" v-for="(error , index) in errors.form.name" :key="index">
                      - {{ error }} <br>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="12" v-else>
                  <b-form-group id="nameInputGroup1"
                                label="Voucher Name"
                                label-for="name">
                    <b-form-input id="name"
                                  type="text"
                                  v-model.lazy.trim="$v.form.name.$model"
                                  :state="chkState('name')"
                                  aria-describedby="nameFeedback"
                                  placeholder="Please Enter Voucher Name"
                                  autocomplete='given-name'
                                  autofocus />
                    <b-form-invalid-feedback id="nameFeedback" v-for="(error , index) in errors.form.name" :key="index">
                      - {{ error }} <br>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="6" v-show="this.form.v_type !== 'serveral_use'">
                  <b-form-group id="qtyInputGroup1"
                                label="Voucher Qty"
                                label-for="qty">
                    <b-form-input id="qty"
                                  type="number"
                                  v-model.lazy.trim="$v.form.qty.$model"
                                  :state="chkState('qty')"
                                  aria-describedby="quantityFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                    <b-form-invalid-feedback id="quantityFeedback" >
                      <span v-if="!$v.form.qty.required">- Jumlah voucher tidak boleh kosong!</span>
                      <span v-if="!$v.form.qty.numeric">- Jumlah voucher tidak boleh kurang dari 0!</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6">
                  <b-form-group id="prefixInputGroup1"
                                label="Voucher Prefix"
                                label-for="prefix">
                    <b-form-input id="prefix"
                                  type="text"
                                  v-model="form.prefix"
                                  aria-describedby="prefixFeedback"
                                  placeholder="Please Enter Voucher Prefix"
                                  onKeyPress="if(this.value.length==3) return false;"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group id="amountInputGroup1"
                                label="Voucher Amount"
                                label-for="amount">
                    <b-form-input id="amount"
                                  type="number"
                                  v-model.lazy.trim="$v.form.amount.$model"
                                  :state="chkState('amount')"
                                  aria-describedby="amountFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                    <b-form-invalid-feedback id="amountFeedback" >
                      <span v-if="!$v.form.amount.required">- Jumlah amount tidak boleh kosong!</span>
                      <span v-if="!$v.form.amount.decimal">- Jumlah amount tidak boleh kurang dari 0!</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6">
                  <!-- <b-form-group label="Start From and End To"
                                label-for="date">
                    <date-picker v-model="mydate" range lang="en" type="datetime" format="YYYY-MM-DD HH:mm:ss" valueType="format" confirm></date-picker>
                    <div  v-if="this.mydate == '' " >
                      <p id="dateFeedback" v-for="(error , index) in errors.form.start" :key="index">
                        - {{ error }} <br>
                      </p>
                    </div>
                  </b-form-group> -->
                  <div>
                    <b-form-group
                      class="mb-3"
                      :label="form.v_type !== 'partnership' ? 'Voucher Period Start From and End To  :' : 'Program Start From and Program End To'"
                      label-for="daterange"
                    >
                      <b-input-group
                      >
                        <b-input-group-prepend is-text>
                          <i class="fa fa-calendar"></i>
                        </b-input-group-prepend>
                        <date-range-picker id="daterange" :start="startDate" :end="endDate" @picker="changePicker"></date-range-picker>
                      </b-input-group>
                      <div  v-if="this.startDate == '' " >
                        <p id="dateFeedback" v-for="(error , index) in errors.form.start" :key="index">
                          - {{ error }} <br>
                        </p>
                      </div>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col sm="6">
                  <b-form-group label="Generate QR Code"
                      label-for="basicInlineCheckboxes">
                    <b-form-checkbox-group id="basicInlineCheckboxes" name="InlineCheckboxes" :plain="true" v-model="qrcode">
                      <b-form-checkbox :plain="true" value="generate">Generate QR</b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6" v-show="this.form.v_type !== 'gold' && this.form.v_type !== 'wallet'">
                  <b-form-group id="rContentInputGroup4"
                                label="Wallet Expired Time Type"
                                label-for="rContent">
                    <b-form-select id="expired_type"
                      v-model.lazy.trim="$v.form.expired_type.$model"
                      :state="chkState('expired_type')"
                      aria-describedby="typeFeedback"
                      :plain="true"
                      :options="[{value: 'minutes', text: 'Minutes'},
                                        {value: 'hours', text: 'Hours'},
                                        {value: 'days', text: 'Days'}]">
                    </b-form-select>
                    <b-form-invalid-feedback id="typeFeedback" v-for="(error , index) in errors.form.expired_type" :key="index">
                      - {{ error }} <br>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="6" v-if="this.form.v_type === 'partnership'">
                  <b-form-group label="Voucher Payment"
                      label-for="basicInlineCheckboxess">
                    <b-form-checkbox-group id="basicInlineCheckboxess" name="InlineCheckboxes" :plain="true" v-model="organic">
                      <b-form-checkbox :plain="true" value="true">Organic</b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </b-col>
                <b-col sm="6" v-if="this.form.v_type === 'wallet'">
                  <b-form-group label="Voucher Payment"
                      label-for="basicInlineCheckboxess">
                    <b-form-checkbox-group id="basicInlineCheckboxess" name="InlineCheckboxes" :plain="true" v-model="wallet">
                      <b-form-checkbox :plain="true" :value="true" disabled>Organic</b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-show="this.form.v_type !== 'gold' && this.form.v_type !== 'wallet'">
                <b-col id="end-wallet">
                  <div id="wallet">
                    <label for="number">Wallet Will Expire On</label>
                    <div class="input-group mb-3">
                      <input type="number" class="form-control" v-model="date" placeholder="Wallet Expire">
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2">{{ this.form.expired_type == '' ? 'minutes' : this.form.expired_type }}</span>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="this.form.v_type === 'serveral_use'">
                <b-col>
                  <b-form-group id="used_for"
                                label="Partner/Organization"
                                label-for="used_for">
                    <b-form-select id="used_for"
                      v-model.lazy.trim="form.used_for"
                      aria-describedby="usedForFeedback"
                      :plain="true"
                      @change="setChange"
                      :options="[
                        {value: 'All', text: 'All User'},
                        {value: 'Treasury', text: 'Treasury User'},
                        {value: 'Partner', text: 'Partner'},
                        {value: 'Organization', text: 'Organization'}]">
                    </b-form-select>
                    <b-form-invalid-feedback id="usedForFeedback" v-for="(error , index) in errors.form.used_for" :key="index">
                      - {{ error }} <br>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="this.form.used_for === 'Partner'">
                <b-col>
                  <b-form-group id="partner"
                                label="Partner"
                                label-for="partner">
                    <b-form-select id="partner"
                      v-model.lazy.trim="form.usage_type"
                      :plain="true"
                      :options="partner">
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="this.form.used_for === 'Organization'">
                <b-col>
                  <b-form-group id="organization"
                                label="Organization"
                                label-for="organization">
                    <b-form-select id="organization"
                      v-model.lazy.trim="form.usage_type"
                      :plain="true"
                      :options="organization">
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="this.form.v_type === 'serveral_use'">
                <b-col sm="6">
                  <b-form-group id="max_allowed"
                                label="Number of User Allowed for Redemption"
                                label-for="max_allowed">
                    <b-form-input id="max_allowed"
                                  type="number"
                                  v-model.lazy.trim="form.max_allowed"
                                  aria-describedby="maxAllowedFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group id="maxAttemps"
                                label="Maximum Usage per User"
                                label-for="maxattemp">
                    <b-form-input id="maxattemp"
                                  type="number"
                                  v-model.lazy.trim="$v.form.max_attempt.$model"
                                  :state="chkState('max_attempt')"
                                  aria-describedby="maxattempFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                    <b-form-invalid-feedback id="maxattempFeedback" >
                      <span v-if="!$v.form.max_attempt.required">- Total Max Usage voucher tidak boleh kosong!</span>
                      <span v-if="!$v.form.max_attempt.numeric">- Total Max Usage voucher tidak boleh kurang dari 0!</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group label="Bucket Type" v-if="this.form.v_type === 'partnership'">
                <b-form-radio-group
                  id="bucket"
                  v-model="form.bucket"
                  :options="buckets"
                  name="radio-options"
                ></b-form-radio-group>
              </b-form-group>
              <b-form-group id="quarantine"
                            v-if="this.form.v_type === 'partnership' && this.form.bucket === this.karantina"
                            label="Masa Karantina (Hari)"
                            label-for="quarantine">
                <b-form-input id="quarantine"
                              type="number"
                              v-model.lazy.trim="$v.form.quarantine.$model"
                              :state="chkState('quarantine')"
                              aria-describedby="quarantineFeedback"
                              autocomplete='given-name'
                              autofocus />
                <b-form-invalid-feedback id="quarantineFeedback" >
                  <span v-if="!$v.form.quarantine.numeric">- Jumlah Masa Karantina tidak boleh kurang dari 0!</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-button type="submit" variant="primary">
                Submit
              </b-button>
            </b-form>
            <br/>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, helpers , numeric} from "vuelidate/lib/validators"
import axios from 'axios'
import dotenv from 'dotenv'
import moment from 'moment'

dotenv.config()

// const end = helpers('end', this.form.end_to <= this.form.start_from)
const decimal = helpers.regex('decimal', /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?$/)

export default {
  name: "generate",
  data() {
    return {
      register_start: moment().format('YYYY-MM-DD'),
      register_end: '',
      startDate: '',
      endDate: '',
      partner: [],
      buckets: [],
      karantina: '',
      organization: [],
      merchantOptions: [],
      form: {
        name: '',
        content: '',
        type: '',
        v_type: '',
        qty: '',
        merchant: '',
        bucket: '',
        amount: '',
        quarantine: '1',
        prefix: '',
        max_attempt: '',
        usage_type: '',
        max_allowed: '',
        used_for: 'All',
        behavior: 'single',
        expired_type: 'minutes',
        user_type: 'All',
        day: ''
      },
      wallet: true,
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
        form: {
          name: [],
          prefix: [],
          start: [],
          end: [],
          user_type: [],
          day: [],
          qty: [],
          max_attempt: [],
          max_allowed: [],
          used_for: [],
          v_type: [],
          expired_type: [],
          amount: []
        },
      },
      date: "",
      qrcode: "",
      isLoading: false,
      organic: false,
      optionsPartnership: [
        'All',
        'New User',
        'Register At',
        'First Time Buyer',
        'Last Time Buy'
      ],
      optionsMultiple: [
        'All',
        'First Time Buyer'
      ]
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2)
      },
      qty: {
        required,
        numeric
      },
      max_attempt: {
        required,
        numeric
      },
      type: {
        required
      },
      v_type: {
        required
      },
      expired_type: {
        required
      },
      amount: {
        required,
        decimal
      },
      day: {
        required,
        numeric
      },
      quarantine: {
        numeric
      }
    }
  },
  created() {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
    this.$http.get(`voucher-types`).then((res) => {
      this.buckets = res.data
      this.form.bucket = this.buckets[0].value
      this.karantina = this.buckets[0].value
    })
    // axios.get(process.env.VUE_APP_SECRET + 'admin/v2/command/merchant').then((response) => {
    //   this.merchantOptions = response.data.data
    // })
  },
  methods: {
    changePicker (value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
    },
    setChange(val) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
      if (val === 'Partner') {
        axios.get(process.env.VUE_APP_SECRET + 'admin/v2/command/partner-command').then((response) => {
          this.partner = response.data.data
        })
      }

      if (val === 'Organization') {
        axios.get(process.env.VUE_APP_SECRET + 'admin/v2/command/organization-command').then((response) => {
          this.organization = response.data.data
        })
      }
    },
    onSubmit() {
      this.validate()
      // if (this.validate()) {
      //   this.$nextTick(() => {
        this.isLoading = true
          const formData =  new FormData();

          formData.append("type", this.form.v_type);
          formData.append("qty", this.form.qty);
          formData.append("amount", this.form.amount);
          formData.append("start_from", this.startDate);
          formData.append("end_to", this.endDate);
          formData.append("wallet_expire_time", this.date);
          formData.append("prefix", this.form.prefix);
          formData.append("name", this.form.name);
          formData.append("qrcode", this.qrcode);
          formData.append("expired_type", this.form.expired_type)
          if (this.form.v_type == 'wallet') {
            formData.append("is_organic", true)
          } else {
            formData.append("is_organic", this.organic)
          }
          // formData.append("merchant", this.form.merchant)
          formData.append("max_attempt", this.form.max_attempt)
          formData.append("used_for", this.form.used_for === 'All' ? 0 : (this.form.used_for === 'Treasury' ? 1 : this.form.usage_type))
          formData.append("type_usage", this.form.used_for)
          formData.append("max_redeem", this.form.max_allowed)
          formData.append("behavior", this.form.behavior)
          formData.append("quarantine", this.form.quarantine)
          formData.append("bucket_type", this.form.bucket)
          formData.append("user_type", this.form.user_type)
          formData.append("register_start", this.register_start)
          formData.append("register_end", this.register_end)
          formData.append("day", this.form.day)

          this.$http.post(`generate-voucher`, formData)
          .then(() => {
            this.isLoading  = false
            this.$router.push("/voucher");
            this.$toasted.success('Voucher successfully created!')
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
              if(this.errors.status == 422) {
                this.$toasted.error('Please fill out the form that must be required')
                this.errors.message = error.response.data.meta.message;
                this.errors.form.name = this.errors.message.name;
                this.errors.form.v_type = this.errors.message.type;
                this.errors.form.qty = this.errors.message.quantity;
                this.errors.form.max_attempt = this.erros.message.max_attempt;
                this.errors.form.amount = this.errors.message.amount;
                this.errors.form.prefix = this.errors.message.prefix;
                this.errors.form.start = this.errors.message.start_from;
              }else if(this.errors.status == 400){
                this.errors.message = error.response.data.meta.message;
                this.$swal.fire(
                  'Failed!',
                  this.errors.message,
                  'error'
                )
              }
            }
          })
      //   })

      // }
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    },
    updateMaxAttemp(value) {
      if (value === 'serveral_use') {
        this.form.max_attempt = '1';
        this.form.qty = '1';
      } else {
        this.form.max_attempt = '';
        this.form.qty = '';
      }
    }
  },
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}


#dateFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
